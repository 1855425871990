// import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'


const Header = ({
    containerClassNames = 'container px-6'
}) => (
    <footer
        className='flex justify-center bg-crimson pb-24'
    >
        <div className={`${containerClassNames}`}>
            <div className='bg-white py-12'>

                <div className='flex py-12'>
                    <div className='flex-1 border-b-8 border-gray-200'></div>
                    <div className='w-32'></div>
                    <div className='flex-1 border-b-8 border-gray-200'></div>
                </div>

                <div className='flex flex-wrap justify-between px-24 text-sm font-serif text-gray-500'>
                    <div className='w-full md:flex-1'>© {new Date().getFullYear()}, Owens Concrete Polishing</div>
                </div>
            </div>
        </div>
    </footer>
)

Header.propTypes = {
    containerClassNames: PropTypes.string,
}

export default Header
