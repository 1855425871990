import React, { useState, useEffect, useRef } from 'react'
// import { Link } from 'gatsby'
// import PropTypes from 'prop-types'
import hotkeys from 'hotkeys-js'
import { observeElementInViewport } from 'observe-element-in-viewport'

import Link from '@components/primitives/link'
// import Logo from '@images/svg/logo-stroked.svg'
import MenuOverlay from './menu/overlay'
import Transition from './transition'


// const { colors } = require('../../tailwind.config').theme.extend


const Header = ({
    className = '',
    style = {}
}) => {

    const logoTopClasses = 'h-10 sm:h-24'
    const logoScrolledClasses = 'h-8 sm:h-12 text-transparent'

    const [isOpen, setIsOpen] = useState(false)

    const expandZoneRef = useRef(null)
    const navbarHeaderRef = useRef(null)
    const logoRef = useRef(null)

    useEffect(() => {
        // console.log('isOpen', isOpen)

        if (isOpen) {
            hotkeys('escape', (event, handler) => {
                // event.preventDefault()
                setIsOpen(false)
            })
        } else {
            hotkeys.unbind('escape')
        }

        // the returned function, when called, stops tracking the target element in the
        // given viewport
        const unobserve = observeElementInViewport(expandZoneRef.current, async (_, unobserve, expandZoneElem) => {
            // On element enter viewport
            // console.log('Entered', logoRef)

            if (logoRef.current === null) return

            logoRef.current.classList.remove(...logoScrolledClasses.split(' '))
            logoRef.current.classList.add(...logoTopClasses.split(' '))

            // Turn off this observer
            // unobserve()
        }, () => {
            // On element exit viewport
            // console.log('Exited', logoRef)

            if (logoRef.current === null) return

            logoRef.current.classList.remove(...logoTopClasses.split(' '))
            logoRef.current.classList.add(...logoScrolledClasses.split(' '))
        }, {
            threshold: [ 0 ]
        })

        // Destroy/unMount
        return () => {
            // console.log('Destroying Header')

            // Clean up observer
            unobserve()

            // Clean up key binding
            hotkeys.unbind('escape')
        }

    }, [
        isOpen
    ])

    return (
        <div className='header-wrapper'>
            <div
                ref={expandZoneRef}
                className='navbar-expand-zone absolute h-1 w-full'
                style={{ zIndex: 9999 }}
            />
            <header
                ref={navbarHeaderRef}
                className={`fixed top-0 left-0 right-0 pb-6 ${className}`}
                style={style}
            >

                <div
                    className={[
                        'relative'
                    ].join(' ')}
                    style={{
                        backgroundColor: 'rgba(var(--primary-rgb), 0.75)'
                    }}
                >


                    <div className='menu-overlay-position-wrapper absolute top-0 inset-x-0'>
                        <Transition
                            show={isOpen}
                            enter='duration-200 ease-out'
                            enterFrom='opacity-0'
                            enterTo='opacity-100'
                            leave='duration-100 ease-in'
                            leaveFrom='opacity-100'
                            leaveTo='opacity-0'
                        >
                            <MenuOverlay
                                isOpen={isOpen}
                            />
                        </Transition>
                    </div>

                    <div
                        className={[
                            'navbar flex justify-center',
                            'bg-white'
                        ].join(' ')}
                        style={{
                            backgroundImage: 'linear-gradient(180deg, rgba(var(--primary-rgb), 1), rgba(var(--primary-rgb), .50))',
                            // backgroundColor: 'var(--primary)',
                            backfaceVisibility: 'hidden',
                            transform: 'translate3d(0,0,0)'
                        }}
                    >
                        <div
                            className='navbar-container container flex justify-between items-center md:justify-start md:space-x-10 px-4 sm:px-6'
                        >

                            <div className='lg:w-0 lg:flex-1'>
                                <Link
                                    title='Owens Concrete Polishing'
                                    to='/'
                                >
                                    <img
                                        src={'/logo/full-treatment.svg'}
                                        alt='Owens Concrete Polishing Logo'
                                        className='w-48 h-auto'
                                    />
                                </Link>
                            </div>

                            <div className='menu-right flex items-center text-xs md:text-sm'>

                                <div className='menu-toggle'>
                                    <button
                                        aria-label='Open Menu'
                                        role='menu'
                                        className='relative px-3 h-32 bg-crimson text-white text-center tracking-widest uppercase text-xs font-bold pt-8 pb-6 flex flex-col items-center justify-between hover:bg-gray-900'
                                        onClick={() => setIsOpen(!isOpen)}
                                    >
                                        <span>menu</span>

                                        {isOpen ? (
                                            <svg className='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                                                <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M6 18L18 6M6 6l12 12' />
                                            </svg>
                                        ) : (
                                            <div className='flex items-end justify-center'>
                                                <span className='block h-5 w-1 bg-yellow-600'></span>
                                                <span className='block h-8 w-1 bg-yellow-600 mx-1'></span>
                                                <span className='block h-5 w-1 bg-yellow-600'></span>
                                            </div>
                                        )}
                                    </button>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>

            </header>
        </div>
    )

}

// Header.propTypes = {
//     className: PropTypes.string,
// }

export default Header
