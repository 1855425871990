import React, { useState } from 'react'

import Transition from '@components/transition'
import Link from '@components/primitives/link'


const iconSizeClasses = 'h-8 w-8'

const fadeInDuration = '2s'

const DownArrow = () => (
    <svg className={`text-secondary-text ${iconSizeClasses} group-hover:text-gray-500 group-focus:text-gray-500`} viewBox='0 0 20 20' fill='currentColor'>
        <path fillRule='evenodd' d='M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z' clipRule='evenodd' />
    </svg>
)

const CloseIcon = () => (
    <svg className={` ${iconSizeClasses}`} fill='none' viewBox='0 0 24 24' stroke='currentColor'>
        <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M6 18L18 6M6 6l12 12' />
    </svg>
)


const MenuItemLabel = ({ title }) => (
    <div
        className='text-3xl leading-9 font-medium text-crimson text-right'
        dangerouslySetInnerHTML={{ __html: title }}
    />
)


const TopLevelMenuItem = (
    item,
    index
) => {

    // console.log('item', item)

    const textClasses = 'text-sm leading-6 font-medium text-gray-500 hover:text-crimson focus:text-crimson'

    const {
        title,
        url,
        wordpress_children: item_children
    } = item

    const [isOpen, setIsOpen] = useState(false)

    // const isActive =
    const hasChildren = !!item_children

    const menuItemProps = {
        tabIndex: (index + 1) * 100,
        style: {
            animationDelay: `${index * 50}ms`,
            animationDuration: fadeInDuration
        }
    }

    const menuItemClasses = [
        `menu-item menu-item-${index}`,
        'animate__animated animate__fadeIn',
        item.classes
    ].join(' ')

    if (!hasChildren) return (
        <Link
            key={index}
            to={url}
            className={[
                menuItemClasses,
                textClasses,
                'group inline-flex items-center justify-end space-x-3 py-4',
            ].join(' ')}
            { ...menuItemProps }
        >
            <MenuItemLabel
                title={title}
            />
            <div
                className={iconSizeClasses}
            />
        </Link>
    )


    /* <!-- Item active: 'text-gold', Item inactive: 'text-gray-500' --> */
    return (
        <div
            key={index}
            className={[
                menuItemClasses,
                'relative'
            ].join(' ')}
        >
            <button
                aria-label={isOpen ? `Close ${title}` : `Open ${title}`}
                type='button'
                className={[
                    menuItemClasses,
                    `menu-item-button w-full group inline-flex items-center justify-end space-x-3 py-4`,
                    textClasses
                ].join(' ')}
                // onHover={() => setIsOpen(!isOpen)}
                onClick={() => setIsOpen(!isOpen)}
                { ...menuItemProps }
            >
                <MenuItemLabel
                    title={title}
                />

                {isOpen ? (
                    <CloseIcon />
                ) : (
                    <DownArrow />
                )}
            </button>

            <Transition
                show={isOpen}
                enter='transition ease-out duration-200'
                enterFrom='opacity-0 translate-y-1'
                enterTo='opacity-100 translate-y-0'
                leave='transition ease-in duration-150'
                leaveFrom='opacity-100 translate-y-0'
                leaveTo='opacity-0 translate-y-1'
            >

                <div className='menu-child-items flex flex-col items-end'>
                    <hr className='w-full' />

                    <div className='w-full max-w-md md:max-w-3xl space-y-6 px-5 pt-6 pb-16'>
                        <div className='grid gap-6 sm:gap-8 sm:p-8 lg:grid-cols-2'>
                            <Link
                                to={url}
                                className={[
                                    textClasses,
                                    'row-start-1 md:col-span-2 flex items-start space-x-4',
                                    'animate__animated animate__fadeIn',
                                ].join(' ')}
                                tabIndex={menuItemProps.tabIndex + 1}
                                style={{
                                    animationDuration: fadeInDuration
                                }}
                            >
                                <p className='text-base leading-6 font-medium text-crimson'>
                                    { title }
                                </p>
                            </Link>

                            {item_children.map((child_item, index) => (
                                <Link
                                    key={index}
                                    to={child_item.url}
                                    className={[
                                        'flex items-start space-x-4',
                                        'animate__animated animate__fadeIn',
                                    ].join(' ')}
                                    tabIndex={menuItemProps.tabIndex + index + 2}
                                    style={{
                                        animationDelay: `${(index+1) * 5}ms`,
                                        animationDuration: fadeInDuration
                                    }}
                                >
                                    <div className='space-y-1'>
                                        <span
                                            className='text-base leading-6 font-medium text-crimson'
                                            dangerouslySetInnerHTML={{ __html: child_item.title }}
                                        />
                                    </div>
                                </Link>
                            ))}
                        </div>
                    </div>

                </div>

            </Transition>
        </div>
    )
}

export default TopLevelMenuItem
