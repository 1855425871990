function getSiteURL () {
    const hasWindow = (typeof window !== 'undefined')

    // console.log('window.location', window.location)

    if (hasWindow) return window.location.origin

    return process.env.URL
}

const siteUrl = getSiteURL()

const siteUrlInfo = new URL(siteUrl)

function isUrlRelative ( originalUrlString ) {
    return originalUrlString.startsWith('/')
}

function isUrlLocal ( originalUrlString ) {
    if (isUrlRelative(originalUrlString)) return true

    return originalUrlString.includes(`://${siteUrlInfo.hostname}`)
}

function makeLocalUrlRelative ( originalUrlString ) {

    if (!isUrlLocal(originalUrlString) || isUrlRelative(originalUrlString)) return originalUrlString


    const hrefInfo = new URL(originalUrlString)

    return hrefInfo.pathname
}


module.exports = {
    isUrlRelative,
    isUrlLocal,
    makeLocalUrlRelative
}

// export default makeLocalUrlRelative
