module.exports = {
  purge: false,
  theme: {
    extend: {
        colors: {
            crimson: 'rgb(110, 42, 61)',
            // crimson: 'rgb(133, 20, 75)',

            gold: 'rgb(188, 115, 50)',

            white: 'rgb(255, 255, 255)',


            // Lightness

            'white-1': 'rgba(255, 255, 255, 0.1)',
            'white-2': 'rgba(255, 255, 255, 0.2)',
            'white-3': 'rgba(255, 255, 255, 0.3)',
            'white-4': 'rgba(255, 255, 255, 0.4)',
            'white-5': 'rgba(255, 255, 255, 0.5)',
            'white-6': 'rgba(255, 255, 255, 0.6)',
            'white-7': 'rgba(255, 255, 255, 0.7)',
            'white-8': 'rgba(255, 255, 255, 0.8)',
            'white-9': 'rgba(255, 255, 255, 0.9)',

            'black-1': 'rgba(0, 0, 0, 0.1)',
            'black-7': 'rgba(0, 0, 0, 0.7)',
            'black-9': 'rgba(0, 0, 0, 0.9)'

        },
        fontFamily: {
            primary: 'work-sans, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
            // secondary: 'work-sans, Georgia, Cambria, "Times New Roman", Times, seri',
        },
        letterSpacing: {
            wide: '0.3em',
            wider: '0.4em',
            widest: '0.5em',
        },
        height: {
            '1/2-screen': '50vh',
            'full-screen': '100vh'
        },
        minHeight: {
            '3/4-screen': '75vh',
        },
        transitionTimingFunction: {
            'ease-out-expo': 'cubic-bezier(0.19, 1, 0.22, 1)'
        },
        zIndex: {
            'header': 1000
        }
    }
  },
  variants: {},
  plugins: []
}
