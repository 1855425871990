
import React, { useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import MenuItem from './top'

let focusedElBeforeOpen


const lockBodyScroll = () => document.body.classList.add('overflow-hidden')

const unlockBodyScroll = () => document.body.classList.remove('overflow-hidden')

const MenuOverlay = ({
    isOpen = false
}) => {

    const {
        wordpressWpApiMenusMenusItems: {
            items
        }
    } = useStaticQuery(graphql`
        query MenuQuery {
            wordpressWpApiMenusMenusItems(slug: {eq: "primary"}) {
                name
                items {
                    title
                    classes
                    description
                    object
                    object_slug
                    object_id
                    order
                    target
                    type
                    type_label
                    url
                    attr
                }
            }
        }
    `)

    useEffect(() => {
        // console.log('isOpen', isOpen)

        // console.log('document.activeElement', document.activeElement)

        if (isOpen) {

            // Lock body scrolling
            lockBodyScroll()

            // Save current focused element
            focusedElBeforeOpen = document.activeElement

            document.querySelector('.menu-item-0').focus()

        } else {
            // Unlock body scolling
            unlockBodyScroll()

            // Refocus on element from before
            focusedElBeforeOpen.focus()
        }

        // componentWillUnmount / Clean up
        return () => {
            // console.log('Unmounting Menu Overlay')

            // Unlock body scroll just in case it was triggerd
            unlockBodyScroll()
        }
    }, [
        isOpen
    ])

    return (
        <div className='menu-overlay'>
            <div className='rounded-lg'>
                <div
                    className='menu-overlay-wrapper h-screen overflow-y-scroll rounded-lg divide-y-2 divide-gray-50 bg-white'
                >
                    <div className='menu-overlay-container container space-y-6 py-32 px-5'>

                        <div className='menu-nav-wrapper'>
                            <nav className='grid grid-cols-1 gap-7'>

                                {items.map(MenuItem)}

                            </nav>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

// MenuOverlay.propTypes = {
//     containerClassNames: PropTypes.string,
// }

export default MenuOverlay
