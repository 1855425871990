/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'

import Header from './header'
import Footer from './footer'

// import SlideInText from './typography/slide-in-text'

const Layout = ({
    children,
    mainClassNames = 'min-h-3/4-screen bg-white pt-32'
}) => {

    return (
        <>
            <Header
                className='z-header'
            />

            <div>
                <main
                    className={mainClassNames}
                >

                    {children}

                    {/* <div className='bg-crimson'>

                        <div className='mx-auto max-w-5xl flex flex-wrap justify-center items-center text-center py-20'>

                            <a href='/investments' className='w-full md:w-1/3 px-12'>
                                <h4 className='mb-2 uppercase text-gray-100 text-lg font-bold tracking-widest'>
                                    <SlideInText text={'Mollit anim id est'} alignClass='justify-center' />
                                </h4>
                                <div className='text-sm md:text-lg text-gray-400'>
                                    <SlideInText text={'Incididunt ut labore'} alignClass='justify-center' />
                                </div>
                            </a>

                            <a href='/accomodations' className='w-full md:w-1/3 py-12 px-12 md:border-l-4 md:border-r-4 md:border-gray-700'>
                                <h4 className='mb-2 uppercase text-gray-100 text-lg font-bold tracking-widest'>
                                    <SlideInText text={'Mollit anim id est'} alignClass='justify-center' />
                                </h4>
                                <div className='text-sm md:text-lg text-gray-400'>
                                    <SlideInText text={'Incididunt ut labore'} alignClass='justify-center' />
                                </div>
                            </a>

                            <a href='/homes' className='w-full md:w-1/3 px-12'>
                                <h4 className='mb-2 uppercase text-gray-100 text-lg font-bold tracking-widest'>
                                    <SlideInText text={'Mollit anim id est'} alignClass='justify-center' />
                                </h4>
                                <div className='text-sm md:text-lg text-gray-400'>
                                    <SlideInText text={'Incididunt ut labore'} alignClass='justify-center' />
                                </div>
                            </a>

                        </div>

                    </div> */}

                </main>

                <Footer />
            </div>
        </>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
    mainClassNames: PropTypes.string
}

export default Layout
