import React from 'react'
import PropTypes from 'prop-types'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
// import { onceTransitionEnd } from 'web-animation-club'

// Pull Tailwind config
const {
    // transitionTimingFunction: {'ease-out-expo': easeOutExpo },
    colors
} = require('../../../tailwind.config').theme.extend

const { makeLocalUrlRelative } = require(`../../helpers/url`)

const Link = ({
    to,
    children,
    className = ''
}) => {

  return (
    <AniLink
        cover
        direction="up"
        bg={colors.crimson}
        to={makeLocalUrlRelative(to)}
        className={className}
    >
        { children }
    </AniLink>
  )
}

Link.propTypes = {
    to: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    className: PropTypes.string
}

export default Link
